import { createSlide, createIcons, DATA_TYPES } from "./helpers";
import ReactIcon from "assets/react.svg"
import ExpressIcon from "assets/express.svg"
import JestIcon from "assets/jest.svg"
import MaterialUIIcon from "assets/material-ui.svg"
import WebpackIcon from "assets/webpack.svg"
import StorybookIcon from "assets/storybook.svg"
import JavascriptIcon from "assets/javascript.svg"
import VueIcon from "assets/vue.svg"
import AngularIcon from "assets/angular.svg"
import SvelteIcon from "assets/svelte.svg"

type OutputSlideShape = {
    id: number;
    type: DATA_TYPES.SLIDE;
    dataAnchor: string;
    texts: {
        text: string;
        tag: 'h1' | 'h2';
        key: number;
        delay: number;
    }[];
}

type OutputIconsShape = {
    id: number;
    type: DATA_TYPES.ICONS;
    dataAnchor: string;
    centerIcon: string;
    icons: string[];
    size: number;
}

export const slides: (OutputIconsShape | OutputSlideShape)[] = [
    createSlide(0, [
        ["Hello, my name is", "h2"],
        ["Kuba", "h1"],
    ]),
    createSlide(1, [
        ["I like to create new", "h2"],
        ["things", "h1"],
    ]),
    createSlide(2, [
        ["So far, I have worked mostly in React", "h2"],
    ]),
    createIcons(3, ReactIcon, [
        ExpressIcon, JestIcon, MaterialUIIcon, WebpackIcon, StorybookIcon
    ], 48),
    createSlide(4, [
        ["But I am ready for new", "h2"],
        ["adventure", "h1"],
    ]),
    createIcons(5, JavascriptIcon, [
        VueIcon, AngularIcon, SvelteIcon
    ], 48),
]