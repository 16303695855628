
import {useRef, useState, useEffect} from 'react'
import AnimatedText from 'components/AnimatedText/AnimatedText'
import Slide from 'components/Slide/Slide'
import clsx from 'clsx';
//@ts-ignore
import Pageable from "pageable";
import { slides } from 'data/data';
import IconsPlanet from 'components/IconsPlanet/IconsPlanet';
import { DATA_TYPES } from 'data/helpers';


const CLASSNAMES = {
  'h1':'text-5xl',
  'h2':'text-3xl',
}

const createPageableOptions = (pageSetter: unknown) => ({
  orientation: "vertical",
  pips: false,
  delay: 600
});

export default function Home() {
  const [page, setPage] = useState(0);
  const container = useRef(null);
  const pages = useRef<any>();

  useEffect(() => {
    //https://www.cssscript.com/demo/fullscreen-scrolling-presentation-pageable/#markup
    // https://mobius1.github.io/Pageable/
    // https://mobius1.github.io/Pageable/delay.html#page-1
    pages.current = new Pageable(
      container.current,
      createPageableOptions(setPage)
    );
    pages.current.on("scroll.before", (data:any) => setPage(data.index));
  }, []);

  return (
    <main className="text-gray-50" ref={container}>
      {slides.map(slide =>{ 
        if(slide.type === DATA_TYPES.SLIDE) {
          return (
            <Slide key={slide.id} customClassName='flex flex-col min-h-screen justify-center items-center p-4 text-center' isActive={page===slide.id} dataAnchor={slide.dataAnchor}>
              {slide.texts.map(text => (
                <AnimatedText key={text.key} string={text.text} tag={text.tag} classname={clsx(CLASSNAMES[text.tag], "text-gray-50", 'my-3')} initiate={page===slide.id} delay={text.delay}/>
              ))}
            </Slide>
          )} else {
            return (
              <Slide key={slide.id} customClassName='flex justify-center items-center' isActive={page===slide.id} dataAnchor={slide.dataAnchor}>
                  <IconsPlanet isActive={page===slide.id} size={slide.size} centerIcon={slide.centerIcon} icons={slide.icons}/>
              </Slide>
            )
          }
      })}
    </main>
  )
}
