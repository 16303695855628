const DELAY = 1200

export enum DATA_TYPES {
    SLIDE = "slide",
    ICONS = "icons",
}

export const createText = ( key:number, text: string, tag:"h1" | "h2") => ({
    text,
    tag,
    key,
    delay: (key+1) * DELAY
})

export const createSlide = (id: number, texts: [text: string, tag?: "h1"|"h2"][] )=> ({
    id,
    type: DATA_TYPES.SLIDE as const,
    dataAnchor:`slide-${id+1}`,
    texts: texts.map(([text, tag="h2"],i) => createText(i, text, tag))
})

export const createIcons = (id: number, centerIcon: string, icons: string[], size = 48) => ({
    id,
    type: DATA_TYPES.ICONS as const,
    dataAnchor:`slide-${id+1}`,
    centerIcon,
    icons,
    size
})