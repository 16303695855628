import { useLayoutEffect, useRef, useState, FC } from "react";
import css from "./styles.module.scss";

export interface Props {
  string: string;
  tag: any;
  classname: string;
  delay?: number;
  initiate?: boolean;
}

const AnimatedText: FC<Props> = ({
  string,
  tag: Tag,
  classname,
  delay = 300,
  initiate = false
}) => {
  const element = useRef();
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const letters = string.split("");
  const [frame, setFrame] = useState(0);

  const tick = () => {
    let tickIndex = 0;
    interval.current = setInterval(() => {
      if (tickIndex < letters.length) {
        tickIndex++;
        setFrame(tickIndex);
      } else {
        clearInterval(interval.current as NodeJS.Timer);
      }
    }, 50);
  };

  useLayoutEffect(() => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    setFrame(0);
    if (initiate) {
      timeout.current = setTimeout(() => tick(), delay);
    } else {
      clearInterval(interval.current as NodeJS.Timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiate]);

  return (
    <Tag ref={element} className={`${css.animContainer} ${classname}`}>
      {letters.map((l, i) => (
        <span key={i} className={frame > i ? css.animLetter : ""}>
          {l}
        </span>
      ))}
    </Tag>
  );
};

export default AnimatedText;
