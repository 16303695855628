import {FC, ReactNode} from 'react'
import styles from "./styles.module.scss"
import clsx from "clsx";

interface Props {
    isActive: boolean;
    customClassName?: string;
    children: ReactNode;
    dataAnchor:string;
}

const Slide: FC<Props> = ({isActive, children, customClassName, dataAnchor}) => {
  return (
    <div className={clsx(customClassName,styles.page,isActive && styles.pageActive)} data-anchor={dataAnchor}>
        {children}
    </div>
  )
}

export default Slide