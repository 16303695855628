import React from 'react';
import Home from './pages/Home';


function App() {
  return (
    <div className="App bg-[radial-gradient(#0f172a,#111827)]" >
      <Home/>
    </div>
  );
}

export default App;
