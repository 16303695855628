import {useEffect, useState, FC} from 'react'
import { Planet } from 'react-planet'


const START_ROTATION = 45
const END_ROTATION = 0
const TIMEOUT = 1500

interface Props {
    isActive: boolean;
    centerIcon: string;
    icons: string[];
    size: number;
}

const IconsPlanet: FC<Props> = ({isActive, size, centerIcon, icons}) => {
    const [open, setOpen] = useState(false)
    const [rotation, setRotation] = useState(0)
    
    useEffect(() => {
        if(isActive){
            setTimeout(() => {
                setOpen(true)
            }, TIMEOUT)
            setRotation(START_ROTATION)
        }else{
            setRotation(END_ROTATION)
            setTimeout(() => {
                setOpen(false)
            }, TIMEOUT)
        }
    },[isActive])

  return (
    <div style={{marginTop:`-${size*2}px`, marginLeft:`-${size*2}px`}}>
        <Planet 
        centerContent={
            <div style={{width:(size * 2)}}>
                <img alt="click me" src={centerIcon}/>
            </div>
        }
        open={open}
        autoClose
        orbitRadius={140}
        rotation={rotation}
        friction={7}
        tension={200}
        hideOrbit
        bounceOnOpen
        bounceOnClose
        dragableSatellites
        dragRadiusSatellites={20}
        >
            {icons.map((icon, index) => (
                <div key={index} style={{width:size}}><img src={icon} alt="side icon"/></div>
            ))}
        </Planet>
    </div>
  )
}

export default IconsPlanet